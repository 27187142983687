import tableGetters from "../../templates/table/getters";
import { orderBy } from "lodash";

export default {
  ...tableGetters,
  dataCounts: state => websiteId =>
    state.entity[websiteId]
      ? orderBy(state.entity[websiteId].data, ["date"], "desc")
      : [],
  dataCountsHeaders: (state, getters) =>
    getters.dataCounts(state.ids[0]).map(count => count.date.toString())
};
